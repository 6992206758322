import { Activity } from "../../model/activity";

export const activityActionTypes = {
  NEW_ACTIVITY: 'NEW_ACTIVITY',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
	CLEAR_ACTIVITY: 'CLEAR_ACTIVITY'
};

export const addActivity = (activity: Activity) => (dispatch: any) => {
  return dispatch({
    type: activityActionTypes.NEW_ACTIVITY,
    payload: { activity: activity },
  });
};

export const updateActivity = (activities: Activity[]) => (dispatch: any) => {
  return dispatch({
    type: activityActionTypes.NEW_ACTIVITY,
    payload: { activities: activities },
  });
};

export const clearActivity = () => (dispatch: any) => {
  return dispatch({
    type: activityActionTypes.CLEAR_ACTIVITY,
    payload: { },
  });
};
