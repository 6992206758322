import { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReactHtmlParser from 'react-html-parser'; 
import Button from '@mui/material/Button';
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import "./question.scss";
import { delay, resultTemplate } from '../services';
import Spinner from 'react-spinner-material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { config } from '../config';
import { getTimestamp, getToday } from '../services';
import { Domain } from '../model/activity';
import { logAnswer } from '../api/log';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

type Props = {
	token: string,
	user?: string,
	cid: number,
	title?: string,
	json: string|undefined,
	setCurrent?: Function	
}

type States = {
	answer: string[],
	legislation: any|null,
	about: any|null,
	ADM: any|null,
	started: boolean,
	more: boolean,
	isCompleted: boolean,
	resultTemplate: string
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Question extends Component<Props, States> {
	private data: any = [];
	private model: object;

	constructor(props: Props) {
		super(props);  
    this.state  = {answer: [], legislation: null, about: null, ADM: null, started: false, more: false, isCompleted: false, resultTemplate: ''};
		StylesManager.applyTheme("defaultV2");
		this.model = new Model({});
	}

	componentDidUpdate(prevProps: Props, prevState: States){
    if(this.props.json !== prevProps.json){
      this.componentDidMount();
    }
  }

	componentDidMount(){
		if(this.props.json !== undefined && this.props.json !== '[]') {
			this.setState({answer: [], legislation: null, about: null, ADM: null, started: false});
			this.data = JSON.parse(this.props.json);
			if(this.props.setCurrent !== undefined && this.data?.firstPageIsStarted){
				this.props.setCurrent('START');
			} else if(this.props.setCurrent !== undefined) {
				this.props.setCurrent(this.data?.pages[0].type==='html'?this.data?.pages[0].html:this.data?.pages[0].title);
			}
			this.model = new Model(this.props.json);
			if(this.data.pages.length === 1 && this.data?.firstPageIsStarted){
				// @ts-ignore
				this.model.showNavigationButtons = false;
			}
		}
		// @ts-ignore
		window.survey = this.model;
	}

	onAfterRenderPage = (survey: any, options: any) => {
		if(this.props.json !== undefined && this.props.json !== '[]'){
			let data = JSON.parse(this.props.json);
			let el = data.pages.filter((item: any) => item.name === 'START')[0];
			if(survey.activePage.propertyHash.name === 'START' && this.state.legislation === null && this.state.about === null && this.state.ADM === null && (el.hasOwnProperty('about') || el.hasOwnProperty('legislation') || el.hasOwnProperty('ADM'))) {
				if (el.hasOwnProperty('about')) {
					this.setState({about: el.about});
				}
				if (el.hasOwnProperty('legislation')) {
					this.setState({legislation: el.legislation});
				}
				if (el.hasOwnProperty('ADM')) {
					this.setState({ADM: el.ADM});
				}
			}
		}
  }

	checkInfo = (name: string) => {
		if(this.props.json !== undefined && this.props.json !== '[]') {
			let data = JSON.parse(this.props.json);
			let el = data.pages.filter((item: any) => item.name === name)[0];
			if (el.hasOwnProperty('about') || el.hasOwnProperty('legislation') || el.hasOwnProperty('ADM')) {
				if (el.hasOwnProperty('about')) {
					this.setState({about: el.about});
				} else {
					this.setState({about: null});
				}
				if (el.hasOwnProperty('legislation')) {
					this.setState({legislation: el.legislation});
				} else {
					this.setState({legislation: null});
				}
				if (el.hasOwnProperty('ADM')) {
					this.setState({ADM: el.ADM});
				} else {
					this.setState({ADM: null});
				}
			} else {
				this.setState({about: null, legislation: null});
			}
		}
  }

	onComplete = (survey: any, options: any) => {
		this.setState({isCompleted: true});
		if (this.props.json !== undefined && this.props.json !== '[]') {
			const data = JSON.parse(this.props.json);
			const result = survey.processedCompletedHtml.slice(0, -42);
			const html = resultTemplate(this.props.user, this.props.title, Object.entries(survey.data), data, result);
			this.setState({resultTemplate: html});
			// @ts-ignored
			let domain: Domain = config.domains;
			logAnswer(this.props.token, {domain: domain, categories: {id: this.props.cid}, answer: JSON.stringify(survey.data), time: getTimestamp()});
			let el = data.completedHtmlOnCondition.filter((item: any) => item.html === survey.renderedCompletedHtml)[0];
			if (el?.hasOwnProperty('about') || el?.hasOwnProperty('legislation') || el?.hasOwnProperty('ADM')) {
				if (el?.hasOwnProperty('about')) {
					this.setState({started: false, about: el.about});
				}
				if (el?.hasOwnProperty('legislation')) {
					this.setState({started: false, legislation: el.legislation});
				}
				if (el?.hasOwnProperty('ADM')) {
					this.setState({started: false, ADM: el.ADM});
				}
			} else {
				this.setState({started: false, about: null, legislation: null, ADM: null});
			}
			if(this.props.setCurrent !== undefined) {
				this.props.setCurrent(survey.renderedCompletedHtml);
			}
		}
  }

	onStarted = (survey: any, options: any) => {
		if(this.props.json !== undefined){
			this.setState({started: true,isCompleted: false});
			const questions = survey.getAllQuestions();
			const obj = JSON.parse(this.props.json);
			let elements: any = {};
			for (let i = 0; i < obj.pages.length; i++) {
				for(let j = 0; j < obj.pages[i].elements.length; j++){
					elements[obj.pages[i].elements[j].name] = obj.pages[i].elements[j]?.visible;
				}
			}
			for (let k = 0; k < questions.length; k++) {
					const q = questions[k];
					if(q.isVisible === true && elements[q.propertyHash.name] === false) {
						q.visible = false;
					}
			}
			this.checkInfo(survey.currentPage.name);
			if(this.props.setCurrent !== undefined) {
				this.props.setCurrent(survey.currentPage.name);
			}
		}
  }

	onCurrentPageChanging = (survey: any, options: any) => {
    let oldPage = options.oldCurrentPage;
    let newPage = options.newCurrentPage;
		this.setState({isCompleted: false});
		if(this.props.setCurrent !== undefined) {
			this.props.setCurrent(newPage.propertyHash.name);
		}
    if (this.state.started){
      delay(100).then(()=>{
        this.checkInfo(survey.currentPage.name);
      });
    }
    if (newPage?.visibleIndex < oldPage?.visibleIndex ) {
      oldPage?.questions.forEach((q: any) => {q.value = null})
    }    
  }

	toggleMore = () => {
		const flag = !this.state.more;
		this.setState({more: flag});
	}

	exportPDF = () => {
		const pdfDocument = {
			content: htmlToPdfmake(this.state.resultTemplate)
		};
		pdfMake.createPdf(pdfDocument).download("UNIVERSAL CREDIT NAVIGATOR - SESSION SUMMARY("+getToday('DD MMMM, YYYY HH:mm')+").pdf");
	}

	render() {
		const style = {
			root: "bg-transparent",
			rootMobile: "bg-transparent",
			navigation: {
				start: "startBtn",
				prev: "prevBtn",
				next: "nextBtn",
				complete: "completeBtn"
			},
			progressBar: "hide",
			progressText: "hide",
			boolean: {
				item: "sd-boolean margin-top-5"
			}
		};
		const modal_style = {
			position: 'absolute' as 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 400,
			bgcolor: 'background.paper',
			border: '2px solid #000',
			boxShadow: 24,
			p: 4,
		};
		return (
			<>
				<Card className={this.props.json !==undefined && this.props.json !== '[]'? 'show bg-transparent': 'hide bg-transparent'} variant="outlined">
      		<CardContent>
						<div className={(this.props.json===undefined||this.props.json==='[]') ? 'loading': 'hide'}>
							<Spinner radius={60} color={"#1ab394"} stroke={5} visible={true} />
							<div className="label">Loading data ...</div>
						</div>
						<div className={(this.props.json===undefined||this.props.json==='[]') ? 'hide': 'question-content'} >
							<Survey css={style} model={this.model} onAfterRenderPage={this.onAfterRenderPage} onStarted={this.onStarted} onCurrentPageChanging={this.onCurrentPageChanging} onComplete={this.onComplete}/>
							<div className={this.state.isCompleted ? 'center': 'hide'}>
								<button className="margin-top-20 export-btn" onClick={()=>this.exportPDF()} >
									Export Result to PDF
								</button>
							</div>
							<div className="">
								{(this.state.legislation!==null&&this.state.legislation!=="")||(this.state.about!==null&&this.state.about!=="")||(this.state.ADM!==null&&this.state.ADM!=="")?(<Button variant="contained" className="more-btn" onClick={()=>this.toggleMore()}>More information</Button>):''}
								<Modal
									open={this.state.more}
									onClose={()=>this.toggleMore()}
									aria-labelledby="modal-modal-title"
									aria-describedby="modal-modal-description"
								>
									<Box sx={modal_style} className="moreInfo">
										<div className="title">More information</div>
										<div className="subtitle">The following is a list of references relevant to this question.</div>
										<hr className="solid margin-top-5 margin-bottom-15" />
										<div className="info">
											{(this.state.legislation!==null&&this.state.legislation!=='')? 
												<div className="label">Legislation</div>
											:''
											}
											{(this.state.legislation!==null&&this.state.legislation!=='')? 
												ReactHtmlParser (this.state.legislation)
												:''
											}
											{(this.state.about!==null&&this.state.about!=='')? 
												<div className="label">About</div>
											:''
											}
											{(this.state.about!==null&&this.state.about!=='')? 
												ReactHtmlParser (this.state.about)
												:''
											}
											{(this.state.ADM!==null&&this.state.ADM!=='')? 
												<div className="label">Decisionmakers Guide</div>
											:''
											}
											{(this.state.ADM!==null&&this.state.ADM!=='')? 
												ReactHtmlParser (this.state.ADM)
												:''
											}
										</div>
									</Box>
								</Modal>
										

							</div>
						</div>
					</CardContent>
				</Card>
			</>
		);
	}
}

export default Question;