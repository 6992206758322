import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import user from '../reducers/user';
import activity from '../reducers/activity';
import CookieStorage from 'zustand-persist-cookie-storage';
import { config } from '../../config';
const persistConfig = {
  key: 'root',
  storage: CookieStorage({domain:config.domainname+'.'+config.tld, expires: 31536000}),
};

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const rootReducers = combineReducers({
  user,
  activity
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = () => {
  const store: any = createStore(persistedReducer, {}, bindMiddleware([thunkMiddleware]));
  store.__persistor = persistStore(store);
  return store;
};

export default store();