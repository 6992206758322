import { Component } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import './styles/navigator.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigatorStep1 from './navigator-step1';
import NavigatorStep2 from './navigator-step2';
import NavigatorStep3 from './navigator-step3';
import { NOTIFICATION_TYPE, notify, withRouter, sortBy } from '../services';
import { Watermark } from '@hirohe/react-watermark';
import { getItems, searchItems } from '../api/loglaw';
import { Role } from '../model/user';
import { Item, ItemArray } from '../model/loglaw';
import { arrayToTree } from "performant-array-to-tree";

type Props = {
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string,
	navigate: NavigateFunction,
	params: any
}
type States = {
	step: string,
	id: number | null,
	cid: number | null,
	data: ItemArray[],
	searchResult: ItemArray[],
	keywords: string
}
class Navigator extends Component<Props, States> {

  constructor(props: Props) {
    super(props);
		const queryParams = new URLSearchParams(window.location.search);
		const id = queryParams.get("id");
		const cid = queryParams.get("cid");
		this.state = {step: this.props.params?.step === undefined ? 'step1' : this.props.params.step, id: id !== null ? Number(id) : null, cid: cid !== null ? Number(cid) : null, data: [], searchResult: [], keywords: ''};
	}

	componentDidMount() {
    document.title = "Universal Credit Navigator - Tiles Navigator Based On DM Case Code Description";
		const roles = this.props.groups.filter((role: Role)=>role.name!=='superadmin'&&role.name!=='admin');
		const selectedrole = roles.map((role: Role)=>role.id)[0];
		const queryParams = new URLSearchParams(window.location.search);
		let keywords: string | null = queryParams.get("keywords");
		if (keywords !== null) {
			this.setState({keywords: keywords});
		}
		if (this.props.logined) {
			getItems(this.props.token, Number(selectedrole)).then((res: any) => {
				let data = res.data;
				data.push({id: 0, parentid: null});
				data = arrayToTree(data, { id: "id", parentId: "parentid", childrenField: "child",dataField: null });
				data = sortBy(data[0].child, true, 'ordernum');
				this.setState({data: data});
			}).catch((error: any) => {
				if(error?.data.code === 401) {
					this.props.navigate('/login');
				}
				notify(NOTIFICATION_TYPE.DANGER, '', error?.data.msg ? error?.data.msg : 'Fetch data failed!');
			});
		}
	}

	componentDidUpdate() {
		const step = this.props.params?.step === undefined ? 'step1' : this.props.params.step;
		if (this.state.step !== step) {
			const queryParams = new URLSearchParams(window.location.search);
			const id = queryParams.get("id");
			const cid = queryParams.get("cid");
			this.setState({step: this.props.params?.step === undefined ? 'step1' : this.props.params.step, id: id !== null ? Number(id) : this.state.id !== null?this.state.id:null, cid: cid !== null ? Number(cid) : this.state.cid !== null?this.state.cid:null});
		}
	}

	search = () => {
		const roles = this.props.groups.filter((role: Role)=>role.name!=='superadmin'&&role.name!=='admin');
		const selectedrole = roles.map((role: Role)=>role.id)[0];
		if(this.state.keywords.trim() === '') {
			this.props.navigate('/');
		} else {
			searchItems(this.props.token, Number(selectedrole), this.state.keywords).then((res: any) => {
				let data = res.data;
				this.setState({searchResult: data, step:'search'});
				this.props.navigate('/search?keywords='+this.state.keywords);
			}).catch((error: any) => {
				if(error?.data.code === 401) {
					this.props.navigate('/login');
				}
				notify(NOTIFICATION_TYPE.DANGER, '', error?.data.msg ? error?.data.msg : 'Fetch data failed!');
			});
		}
	}

	render() {
		const topics = this.state.data.map((item: Item) => {return {id:item.id, name:item.name};});
		let content = (
			<>
				<div className="nav">
					<div className="container">
						{ this.state.step !== 'step1' ? (<><div className="step1" onClick={()=>this.props.navigate('/')}>Select a topic</div>&nbsp;&gt;&nbsp;<div className="step2 active">Review</div></>) : (<></>)}
						<div className="search">
							<TextField
								label="Search"
								size="small"
								color="warning"
								value={this.state.keywords}
								onChange={(event: any) => {this.setState({keywords: event.target.value})}}
								onKeyDown={(event: any) => {if (event.key === 'Enter') {this.search()}}}
								InputProps={{
									endAdornment: (
										<InputAdornment className="search-btn" position="end" onClick={()=>this.search()}>
											<NavigateNextIcon />
										</InputAdornment>
									),
								}}
								variant="outlined"
							/>
						</div>
					</div>
				</div>
				<div className="content">
					{ this.state.step === 'step1' ? (<><NavigatorStep1 topics={topics} /></>) : (<></>)}
					{ this.state.step === 'step2' ? (<><NavigatorStep2 id={this.state.id} cid={this.state.cid} data={this.state.data} /></>) : (<></>)}
					{ this.state.step === 'search' ? (<><NavigatorStep2 data={this.state.searchResult} /></>) : (<></>)}
					{ this.state.step === 'step3' ? (<><NavigatorStep3 id={this.state.cid} pid={this.state.id} /></>) : (<></>)}
				</div>
			</>
		);
		if (this.props.trial) {
      content = (
        <>
          <Watermark
            fontFamily="Arial, Helvetica, sans-serif"
            gutter={-100}
            opacity={0.5}
            rotate={-45}
            show
            text="Concept testing -- not for public display"
            textColor="rgba(0,0,0,0.3)"
            textSize={30}
          >
            { content }
          </Watermark>
        </>
      );
    }
		return (
			<>
				{ content }
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired
});

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigator));