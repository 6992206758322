import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, {
  TreeItemProps,
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

let selectFunc = (event: any) => {};

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref,
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleSelection(event);
		selectFunc(nodeId);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});

function CustomTreeItem(props: TreeItemProps) {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
}

type Item = {
	id: number, 
	name: string,
  ordernum?: number
}

type Items = {
	id: number, 
	name: string,
	child?: Item[],
  ordernum?: number
}

type ItemArray = {
	id: number, 
	name: string,
	child?: Items[],
  ordernum?: number
}

type Props = {
	data?: ItemArray[],
	select?: any,
  selected?: number | string | string[] | undefined
}

const extractTree = (data: ItemArray | undefined) => {
	if(data !== undefined) {
		const {id, name, child} = data;
		if(child) {
			return (
				<CustomTreeItem key={id} nodeId={id.toString()} label={name}>
					{ child.map((item: ItemArray) => extractTree(item)) }
				</CustomTreeItem>
			);
		} else {
			return (
				<CustomTreeItem key={id} nodeId={id.toString()} label={name} />
			);
		}
	} else {
		return "";
	}
	
}

export default function IconExpansionTreeView(props: Props) {
	selectFunc = props.select;
	const data = props.data;
  let selected = props.selected;
	let elements = data?.map((item: ItemArray) => extractTree(item));
  if(selected !== undefined) {
    selected = selected.toString();
  }

  return (
    <TreeView
      aria-label="icon expansion"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={ selected }
      sx={{ flexGrow: 1 }}
    >
			{ elements }
    </TreeView>
  );
}