import { Component } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { NOTIFICATION_TYPE, notify, withRouter } from '../services';

type Item = {
	id: number, 
	name: string
}
type Props = {
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string,
	topics: Item[],
	navigate: NavigateFunction
}
type States = {
	moreHelp: boolean,
	activeTab: number
}
class NavigatorStep1 extends Component<Props, States> {

  constructor(props: Props) {
    super(props);
		this.state = {moreHelp: false, activeTab: 0};
	}

	toggleMore = () => {
		const moreHelp = !this.state.moreHelp;
		this.setState({moreHelp: moreHelp});
	}

	toggleTab = (index: number) => {
		if (this.state.activeTab === index) {
			this.setState({activeTab: 0});
		} else {
			this.setState({activeTab: index});
		}
	}

	nextStep = (title: string) => {
		const item:Item|undefined = this.props.topics.find((item: Item) => item.name === title);
		if (item!==undefined) {
			this.props.navigate('/step2?id='+item?.id);
		} else {
			notify(NOTIFICATION_TYPE.DANGER,'','This topic does not exist!');
		}
	}

	checkNextStep = (title: string) => {
		const item:Item|undefined = this.props.topics.find((item: Item) => item.name === title);
		if (item!==undefined) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		return (
			<>
				<div className="step-title">
					Step 1. Select a topic
				</div>
				<Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
					<Grid item md={9} xs={12}>
						{this.checkNextStep('The Benefit Unit')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 1 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="The Benefit Unit"
								className={this.state.activeTab === 1 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(1)}
							>
								<div className="list-title">
									The Benefit Unit
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 1 ? 'tab-detail active' : ''}>
								<div className="list-content">
									The UC benefit unit (or household) consists of a single person or a couple and the  children or qualifying young persons they are responsible for.
									<br/><br/>
									This section provides definitions of:  
									<br/>
									<ul>
										<li>member of a couple;</li>
										<li>responsibility for a child or qualifying young person;</li>
										<li>qualifying young person.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("The Benefit Unit")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}
						
						{this.checkNextStep('Conditions of Entitlement')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 2 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Conditions of Entitlement"
								className={this.state.activeTab === 2 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(2)}
							>
								<div className="list-title">
									Conditions of Entitlement
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 2 ? 'tab-detail active' : ''}>
								<div className="list-content">
									Entitlement to UC is based on a single or joint claim and depends on claimants meeting the basic conditions and financial conditions. Couples must claim jointly except in certain cases where one member may make a single claim, but the couple’s income and capital are assessed jointly.
									<br/><br/>    
									This section covers:
									<ul>
										<li>the type of claim – whether single or joint;</li>
										<li>the basic conditions (aged at least 18, under state pension age, in Great Britain, not receiving education, accepted a claimant commitment);</li>
										<li>the financial conditions;</li>
										<li>restrictions on entitlement.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Conditions of Entitlement")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('Calculation of Universal Credit')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 3 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Calculation of Universal Credit"
								className={this.state.activeTab === 3 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(3)}
							>
								<div className="list-title">
									Calculation of Universal Credit
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 3 ? 'tab-detail active' : ''}>
								<div className="list-content">
									The basis for UC entitlement is a monthly assessment period. The amount of UC for each assessment period is calculated by adding together the elements and then deducting an amount for income. The deduction for earned income is 55% of the amount that exceeds a work allowance and for unearned income it is 100%.   
									<br/><br/>
									The section covers:
									<ul>
										<li>the assessment period;</li>
										<li>the steps in the calculation;</li>
										<li>the work allowance and taper for earned income.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Calculation of Universal Credit")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('The Elements')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 4 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="The Elements"
								className={this.state.activeTab === 4 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(4)}
							>
								<div className="list-title">
									The Elements
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 4 ? 'tab-detail active' : ''}>
								<div className="list-content">
									The elements that make the maximum amount of UC consist of a  standard allowance and other amounts relating to specific costs.  
									<br/><br/>
									This section covers:
									<ul>
										<li>the standard allowance</li>
										<li>the child element</li>
										<li>the housing costs element</li>
										<li>the LCWRA element</li>
										<li>the carer element</li>
										<li>the childcare costs element</li>
										<li>transitional protection</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("The Elements")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('Capability for Work')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 5 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Capability for Work"
								className={this.state.activeTab === 5 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(5)}
							>
								<div className="list-title">
									Capability for Work
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 5 ? 'tab-detail active' : ''}>
								<div className="list-content">
									Whether a claimant has capability for work may affect whether they meet the entitlement conditions, the amount of the UC award and work-related requirements.
									<br/><br/>
									This section defines the tests for:
									<ul>
										<li>limited capability for work;</li>
										<li>limited capability for work and work-related activity.</li>
									</ul>
									It also covers the work capability assessment 
									<br/>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Capability for Work")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('Capital and Income')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 6 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Capital and Income"
								className={this.state.activeTab === 6 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(6)}
							>
								<div className="list-title">
									Capital and Income
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 6 ? 'tab-detail active' : ''}>
								<div className="list-content">
									The amount of a claimant's capital or income may affect whether they meet the entitlement conditions, the amount of the UC award and whether they are subject to work-related requirements. 
									<br/><br/>
									This section deals with the definition and calculation of: 
									<ul>
										<li>capital (including capital that is disregarded);</li>
										<li>earned income (including employed and self-employed earnings);</li>
										<li>unearned income.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Capital and Income")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}
						
						{this.checkNextStep('The Benefit Cap')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 7 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="The Benefit Cap"
								className={this.state.activeTab === 7 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(7)}
							>
								<div className="list-title">
									The Benefit Cap
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 7 ? 'tab-detail active' : ''}>
								<div className="list-content">
									The benefit cap is a reduction in a person’s UC entitlement where their UC award, combined with certain other benefits, exceeds a specified amount.
									<br/><br/>
									This section covers: 
									<ul>
										<li>the reduction;</li>
										<li>exceptions to the benefit cap.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("The Benefit Cap")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('Work-Related Requirements')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 8 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Work-Related Requirements"
								className={this.state.activeTab === 8 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(8)}
							>
								<div className="list-title">
									Work-Related Requirements
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 8 ? 'tab-detail active' : ''}>
								<div className="list-content">
									UC claimants may be subject to different types of work-related requirements according to their circumstances. 
									<br/><br/>
									This section covers: 
									<ul>
										<li>the types of work-related requirement (including work-focused interviews, work-preparation, work availability and work search);</li>
										<li>the work groups.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Work-Related Requirements")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('Sanctions')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 9 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Sanctions"
								className={this.state.activeTab === 9 ? 'tab-title active' : 'theme-green3-bg'}
								onClick={()=>this.toggleTab(9)}
							>
								<div className="list-title">
									Sanctions
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 9 ? 'tab-detail active' : ''}>
								<div className="list-content">
									Failure to comply with work-related requirements without good reason can result in a  reduction of universal credit (a sanction).
									<br/><br/>
									This section covers:
									<ul>
										<li>the types of sanction;</li>
										<li>the amount of the reduction;</li>
										<li>hardship payments.</li>
									</ul>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Sanctions")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}

						{this.checkNextStep('Tax credit closure: pension age claimants')&&<Accordion className="margin-bottom-8">
							<AccordionSummary
								expandIcon={this.state.activeTab !== 10 ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
								aria-controls="Tax credit closure: pension age claimants"
								className={this.state.activeTab === 10 ? 'tab-title active' : 'theme-purple5-bg'}
								onClick={()=>this.toggleTab(10)}
							>
								<div className="list-title">
									Tax credit closure: pension age claimants
								</div>
							</AccordionSummary>
							<AccordionDetails className={this.state.activeTab === 10 ? 'tab-detail active' : ''}>
								<div className="list-content">
									CLOSURE OF TAX CREDITS: MIGRATION OF PENSION AGE CLAIMANTS AND TRANSITIONAL PROTECTION
									<br/><br/>
									This section has been added for the migration of claimants who are over state pension age from tax credits to universal credit or state pension credit. Claimants on working tax credit who receive a migration notice will be able to claim universal credit with transitional protection. Claimants on child tax credit, or already on state pension credit, who receive a tax credit closure notice may qualify for transitional protection in state pension credit. This is covered by the Social Security (Pension Age Claimants: Closure of Tax Credits)(Amendment) Regulations 2024
									<br/>
									<Button className="select" variant="contained" onClick={()=>this.nextStep("Tax credit closure: pension age claimants")} >Select</Button>
								</div>
							</AccordionDetails>
						</Accordion>}
					</Grid>
					<Grid item md={3} xs={12}>
						<div className="helpinfo">
							<div className="title">Help using this page</div>
							<div className="details">
							This online tool can help you find answers to questions about legal entitlement to universal credit. Select a topic to explore and then choose from the list of questions. You will then be guided interactively through the legislation. Select "More information" at any point to see the official version of the legislation, to explore a related question or to see other relevant information or guidance.
								{/* <div className={this.state.moreHelp?'show':'hide'}>
									continue text here ...
								</div>
								<div className={this.state.moreHelp ? 'hide' : 'more show'} onClick={this.toggleMore}>Show more</div>
								<div className={this.state.moreHelp ? 'more show' : 'hide'} onClick={this.toggleMore}>Show less</div> */}
							</div>
						</div>
					</Grid>
				</Grid>
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired
});

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigatorStep1));
