import moment from 'moment-timezone';
import { Store } from 'react-notifications-component';
import CryptoJS from 'crypto-js';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {Node,Edge} from 'reactflow'; 

export const getTimestamp = () => {
  return moment().unix();
}

export const getToday = (format?: string) => {
  return moment().format(format?format:'DD/MM/YYYY');
}

export const getDate = (num: any, type: any, format?: string) => {
  return moment().subtract(num, type).format(format?format:'DD/MM/YYYY');
}

export const getYear = (format?: string) => {
  return moment().format(format?format:'YYYY');
}

export const getTime = (timezone?: string, format?: string, minutes?: number) => {
  if (timezone) {
    return moment().subtract(minutes?-1*minutes:0, 'minutes').tz(timezone).format(format?format:'YYYY-MM-DD HH:mm:ss');
  } else {
    return moment().subtract(minutes?-1*minutes:0, 'minutes').format(format?format:'YYYY-MM-DD HH:mm:ss');
  }
}

export const timestampFormat = (timestamp: number, timezone?: string, format?: string) => {
  return moment(timestamp*1000).tz(timezone?timezone:'Pacific/Auckland').format(format?format:'DD/MM/YYYY');
}

export const currencyFormat = (num: number, precision?: number) => {
  let dec = num.toFixed(precision?precision:2).split(".")[1];
  if(precision !== 0){
    return '$' + Math.floor(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+dec;
  }else{
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}

export const changeFormat = (num: number) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%';
}

export const numberFormat = (num: number, precision?: number) => {
  let dec = num.toFixed(precision?precision:2).split(".")[1];
  if(precision !== 0){
    return Math.floor(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+dec;
  }else{
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
export const dateFormat = (num: number) => {
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  const year = num.toString().substr(2,2);
  const month = months[parseInt(num.toString().substr(4,2))-1];
  const day = num.toString().substr(6,2);
  return day+' '+month+' '+year;
}

export const delay = async (ms: number) => {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export enum NOTIFICATION_TYPE {
  SUCCESS = 'success',
  DANGER = 'danger',
  INFO = 'info',
  DEFAULT = 'default',
  WARNING = 'warning'
}

export enum NOTIFICATION_CONTAINER {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  CENTER = 'center',
  TOP_FULL = 'top-full',
  BOTTOM_FULL = 'bottom-full'
}

export enum INSERTION {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export const notify = (type: NOTIFICATION_TYPE, title: string, msg: string, duration = 3000) => {
  const notification = {
    title: title,
    message: msg,
    type: type,
    insert: INSERTION.TOP,
    container: NOTIFICATION_CONTAINER.TOP_RIGHT,
    animationIn: ["animate__animated animate__fadeIn"],
    animationOut: ["animate__animated animate__fadeOut"]
  };
  Store.addNotification({
    ...notification,
    type: type,
    dismiss: {
      duration: duration
    }
  });
}

export const encryptWithAES = (text: string) => {
  const passphrase = 'ucnavigator';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext: string) => {
  const passphrase = 'ucnavigator';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const withRouter = (Component: any) =>  {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        navigate={navigate}
        location={location}
        params={params}
        {...props}
      />
    );
  }
  return ComponentWithRouterProp;
}

export const sortBy = (data: any[], direction: boolean, column: string) => {
  data.sort((a, b) => direction?(a[column] - b[column]):(b[column] - a[column]));
  data.forEach(item => {
      if (item.child) {
        sortBy(item.child, direction, column);
      }
  });
  return data;
}

export const resultTemplate = (username: string|undefined, title: string|undefined, result: any[], json: any, outcome: string) => {
  let html = "<div><h3>UNIVERSAL CREDIT NAVIGATOR - SESSION SUMMARY</h3><div>USERNAME: "+username+"</div><div>RULE EXAMINED: "+title+"</div><div>DATE: "+getToday('DD MMMM, YYYY HH:mm')+"</div>";
  result.forEach(([key, value])=>{
    let question = findQuestionByName(json.pages, key);
    let legislation = findLegislationByName(json.pages, key);
    html += "<hr><div><b>Question:</b> "+question+"</div><br><div><b>Answer:</b> "+(value?'Yes':'No')+"</div><br><b>LEGISLATION</b><div>"+legislation+"</div>";
  });
  html += "<hr><b>Outcome:</b><br><div>"+outcome+"</div></div>";
  return html;
}

export const findNameById = (items: any[], id: any):any => {
  for(let i = 0; i < items.length; i++) {
    if(items[i].id === id) {
      return items[i].name;
    }
    if(items[i]?.child?.length>0){
      let result = findNameById(items[i].child, id);
      if(result !== undefined) {
        return result;
      }
    }
  };
  return undefined;
}

export const findQuestionByName = (items: any[], name: string) => {
  for(let i = 0; i < items.length; i++) {
    for(let j = 0; j < items[i].elements.length; j++) {
      if(items[i].elements[j].name === name) {
        if(items[i].elements[j]?.title === '-') {
          return items[i].elements[j-1]?.html;
        }
        return items[i].elements[j]?.html?items[i].elements[j]?.html:items[i].elements[j]?.title;
      }
    }
  };
  return undefined;
}

export const findLegislationByName = (items: any[], name: string) => {
  for(let i = 0; i < items.length; i++) {
    for(let j = 0; j < items[i].elements.length; j++) {
      if(items[i].elements[j].name === name) {
        return items[i]?.legislation?items[i]?.legislation:'';
      }
    }
  };
  return undefined;
}

export const flowToJson = (nodes:Node[], edges:Edge[]) => {
  let json = {type:'canvas', pages: [],widthMode: "responsive", requiredText: "", completedHtml: "<b>You shouldn't be seeing this message.</b><br><br><button onClick='window.survey.clear();window.survey.render();'>Restart</button>",showPageTitles: false,showProgressBar: "top",firstPageIsStarted: true,completedHtmlOnCondition:[]};
  if (nodes !== undefined && edges !== undefined) {
    let pages = nodes.filter((node: Node)=>node.data.name !== 'Result').sort((a: any, b: any)=>a.data.order-b.data.order);
    let results = nodes.filter((node: Node)=>node.data.name === 'Result').sort((a: any, b: any)=>a.data.order-b.data.order);
    edges.map((edge: Edge)=>{ 
      if (edge.label === 'Yes' || edge.label === 'No') {
        let sourceNode = pages.filter((node: Node)=>edge.source === node.id);
        if(sourceNode.length>0){
          pages = pages.map((node: any)=>{
            if(edge.target === node.id){
              node.data.visible = false;
              if (node.data.visibleIf === undefined || node.data.visibleIf === '') {
                node.data.visibleIf = "{"+sourceNode[0].data.elements.at(-1).name+"} = "+(edge.label==='Yes'?"true":"false");
              } else {
                node.data.visibleIf += " or {"+sourceNode[0].data.elements.at(-1).name+"} = "+(edge.label==='Yes'?"true":"false");
              }
            }
            return node;
          });
          results = results.map((node: any)=>{
            if(edge.target === node.id){
              if (node.data.expression === undefined || node.data.expression === '') {
                node.data.expression = "{"+sourceNode[0].data.elements.at(-1).name+"} = "+(edge.label==='Yes'?"true":"false");
              } else {
                node.data.expression += " or {"+sourceNode[0].data.elements.at(-1).name+"} = "+(edge.label==='Yes'?"true":"false");
              }
            }
            return node;
          });
        }
      }
      return edge;
    });
    //@ts-ignore
    json.pages = pages.map((node: any)=>node.data);
    //@ts-ignore
    json.completedHtmlOnCondition = results.map((node: any)=>{return {html:node.data.elements[0].html+"<br><br><button onClick='window.survey.clear();window.survey.render();'>Restart</button>", expression:node.data.expression};});
  }
  return JSON.stringify(json);
}