export const userActionTypes = {
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  UPDATE_EXPIRED: 'UPDATE_EXPIRED',
  UPDATE_GROUP: 'UPDATE_GROUP',
  UPDATE_TRIAL: 'UPDATE_TRIAL',
  LOGINED: 'LOGINED'
};

export const updateUser = (user: any) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_USER,
    payload: { user: user },
  });
};

export const updateToken = (token: string) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_TOKEN,
    payload: { token: token },
  });
};

export const updateExpired = (expired: string) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_EXPIRED,
    payload: { expired: expired },
  });
};

export const updateGroup = (groups: object) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_GROUP,
    payload: { groups: groups },
  });
};

export const updateTrial= (trial: boolean) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_TRIAL,
    payload: { trial: trial },
  });
};

export const updateLogined = (logined: boolean) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.LOGINED,
    payload: { logined: logined },
  });
};