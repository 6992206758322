import { activityActionTypes } from '../actions/activity';

const activityInitialState = {
  activities: []
};

const activityReducer = (state = activityInitialState, action: any) => {
  switch (action.type) {
    case activityActionTypes.NEW_ACTIVITY:
      return {
        ...state,
        activities: [...state.activities, action.payload.activity],
      };
		case activityActionTypes.UPDATE_ACTIVITY:
			return {
				...state,
				activities: action.payload.activities,
			};
    case activityActionTypes.CLEAR_ACTIVITY:
      return {
        ...state,
        activities: [],
      };
    default:
      return state;
  }
};

export default activityReducer;