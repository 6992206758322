import { Component } from 'react';
import './styles/footer.scss';
import { Location, NavigateFunction } from 'react-router-dom';
import { withRouter } from '../services';

type Props = {
	navigate: NavigateFunction,
	location: Location
}
type States = {
}
class Footer extends Component<Props, States> {

	render() {
		let currentYear = new Date().getFullYear();
		return (
			<>
				<div className="footer">
					<div className="container">
						<div className={this.props.location.pathname==='/help'?'link active':'link'} onClick={()=>this.props.navigate('/help')}>Help</div> <div className={this.props.location.pathname==='/privacy'?'link active':'link'} onClick={()=>this.props.navigate('/privacy')}>Privacy</div> <div className={this.props.location.pathname==='/about'?'link active':'link'} onClick={()=>this.props.navigate('/about')}>About this navigator</div> <div className={this.props.location.pathname==='/contact'?'link active':'link'} onClick={()=>this.props.navigate('/contact')}>Contact</div><div className="copyright">&copy; Novallex {currentYear}</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(Footer);