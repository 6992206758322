import { Domain } from './model/activity';
export const subdomain = window.location.hostname.split('.')[0];
export const env = (window.location.hostname.split('.')[0] === 'test' || window.location.hostname.split('.')[1] === 'test')?'test':((window.location.hostname.split('.')[0] === 'uat' || window.location.hostname.split('.')[1] === 'uat')?'uat':((window.location.hostname.split('.')[0] === 'dev' || window.location.hostname.split('.')[1] === 'dev')?'dev':'prod'));
export const tld = window.location.hostname.split('.').slice(-1)[0] === 'com'?'com':(window.location.hostname.split('.').slice(-1)[0] === 'nz'?'co.nz':'co.uk');
export const domainname = tld === 'com'?'novallex':(tld === 'co.nz'?'novallex':'ucnavigator');
export const domain = env !=='prod'? env+'.'+domainname+'.'+tld:domainname+'.'+tld;
export const protocal = window.location.protocol;
const domainid = ["dev.novallex.com","test.novallex.com","uat.novallex.com","novallex.com","test.novallex.co.nz","uat.novallex.co.nz","novallex.co.nz","test.ucnavigator.co.uk","ucnavigator.co.uk"];
export const domains:Domain = {id:domainid.indexOf(domain)+1,domain:domain};
export const config = {
  url: {
    API_URL: env!== 'prod'?'//api.'+domain+'/':'//api.'+domain+'/'
  },
  subdomain: subdomain,
  domain: domain,
  domainname: domainname,
  domains: domains,
  env: env,
  tld: tld,
  protocal: protocal
}
if (env !== 'dev' && window.location.protocol !== 'https:') {
  window.location.href = 'https://'+window.location.hostname+window.location.pathname;
}
export default config;