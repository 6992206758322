import { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { withRouter } from '../services';
import './styles/privacy.scss';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type Props = {
	navigate: NavigateFunction
}
type States = {
}
class Privacy extends Component<Props, States> {

	componentDidMount() {
    document.title = "Privacy -- Universal Credit Navigator";
	}

	render() {
		return (
			<>
				<div className="privacy">
					<div className="nav">
						<div className="container">
							<div className="search">
								<TextField
									label="Search"
									size="small"
									color="warning"
									InputProps={{
										endAdornment: (
											<InputAdornment className="search-btn" position="end">
												<NavigateNextIcon />
											</InputAdornment>
										),
									}}
									variant="outlined"
								/>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="margin-top-25 back-link" onClick={()=>this.props.navigate('/')}>&lt;<span>Back to Navigator</span></div>
						<Grid container className="margin-top-25" spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
							<Grid item md={4} xs={12}>
								<div className="font-20 font-weight-6 theme-grey1-fg">Privacy Policy</div>
							</Grid>
							<Grid item md={8} xs={12}>
								<div className="font-12 line-height-16">
									<p>The <a className="font-style-italic text-underline font-weight-6 theme-darkteal-fg" href="https://www.understandinguniversalcredit.gov.uk" target="_blank" rel="noreferrer">www.understandinguniversalcredit.gov.uk</a> website is provided by the Department for Work and Pensions (DWP).</p>
									
									<p>DWP is the data controller for pages on this site. A data controller determines how and why personal data is processed.</p>
									
									<p>Our <a className="font-style-italic text-none font-weight-6 theme-darkteal-fg" href="https://www.gov.uk/government/organisations/department-for-work-pensions/about/personal-information-charter" target="_blank" rel="noreferrer">Personal Information Charter</a> explains how and why DWP uses your personal information, and your rights and responsibilities. It covers all of the services that DWP provides, and not just this website.</p>

									<p>If you follow a link to a service provided by another government department, agency or local authority, that organisation will:
										<ul>
											<li>be the data controller</li>
											<li>be responsible for processing any data you share with them</li>
											<li>publish and manage their own privacy notice with details of how to contact them</li>
										</ul>
									</p>

									<div className="uppercase font-weight-6">What data we collect on this site</div>

									<p>We would like to use cookies to capture information about how you use the site, and also how effective our advertising has been. More information about the cookies we use, and why, can we found on our <a className="font-style-italic text-none font-weight-6 theme-darkteal-fg" href="https://www.understandinguniversalcredit.gov.uk/cookies/" target="_blank" rel="noreferrer">Cookies page</a>. You can also use this page to manage your choices.</p>

									<p>We won't store information or cookies unless you give us permission. We don't store any other personal information.</p>

									<div className="uppercase font-weight-6">Our lawful basis for processing your data</div>

									<p>The lawful basis for processing data for analytics or marketing is your consent.</p>

									<div className="uppercase font-weight-6">What we do with your data</div>

									<p>The data we collect may be shared with other government departments, agencies and public bodies. It may also be shared with our technology suppliers, for example our hosting provider.</p>

									<p>We will not sell or rent your data to third parties.</p>

									<div className="uppercase font-weight-6">How long we keep your data</div>

									<p>Your cookie data will be stored under the privacy policy of the service providers we use. You can find a <a className="font-style-italic text-none font-weight-6 theme-darkteal-fg" href="https://www.understandinguniversalcredit.gov.uk/cookies/" target="_blank" rel="noreferrer">list of the cookies we use</a>, and how long they are stored, on our cookies page. You can manage your choices at any time.</p>

									<div className="uppercase font-weight-6">Where your data is processed and stored</div>

									<p>Data collected for analytics or marketing purposes may be transferred outside the EEA for processing by the service provider.</p>

									<div className="uppercase font-weight-6">Your rights</div>

									<p>We will not set analytics or marketing cookies without your permission. You can manage your choices at any time.</p>

									<div className="uppercase font-weight-6">Links to other websites</div>

									<p><a className="font-style-italic text-underline font-weight-6 theme-darkteal-fg" href="https://www.understandinguniversalcredit.gov.uk" target="_blank" rel="noreferrer">www.understandinguniversalcredit.gov.uk</a> contains links to other websites.</p>

									<p>This privacy notice only applies to this website, and does not cover other government services and transactions that we link to. These services have their own terms and conditions and privacy policies.</p>

									<p>If you come to understandinguniversalcredit.gov.uk from another website, we may receive personal information from the other website. You should read the privacy policy of the website you came from to find out more about this.</p>

									<div className="uppercase font-weight-6">Queries or complaints</div>

									<p>Contact us if you have a question about anything in this privacy notice.</p>

									<p>The Data Protection Officer provides independent advice and monitoring of our use of personal information. You can contact them by email at <a className="font-style-italic text-underline font-weight-6 theme-darkteal-fg" href="mailto:data.protectionofficer@dwp.gov.uk" target="_blank" rel="noreferrer">data.protectionofficer@dwp.gov.uk</a> or at:</p>

									<p>
										DWP Data Protection Team<br/>
										Benton Park View 6<br/>
										Room BP6001<br/>
										Mail Handling Site A<br/>
										Wolverhampton<br/>
										WV98 1ZX
									</p>

									<p>You can also make a complaint to the Information Commissioner, who is an independent regulator.<br/>
									Email: <a className="font-style-italic text-underline font-weight-6 theme-darkteal-fg" href="mailto:casework@ico.org.uk" target="_blank" rel="noreferrer">casework@ico.org.uk</a><br/>
									Telephone: 0303 123 1113</p>
									
								</div>
							</Grid>
							<Grid item xs={12}>
								<a className="backtotop-link" href="#header">Back to Top</a>
							</Grid>
						</Grid>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(Privacy);