import { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { withRouter } from '../services';
import './styles/about.scss';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type Props = {
	navigate: NavigateFunction
}
type States = {
}
class About extends Component<Props, States> {

	componentDidMount() {
    document.title = "About this navigator -- Universal Credit Navigator";
	}

	render() {
		return (
			<>
				<div className="about">
					<div className="nav">
						<div className="container">
							<div className="search">
								<TextField
									label="Search"
									size="small"
									color="warning"
									InputProps={{
										endAdornment: (
											<InputAdornment className="search-btn" position="end">
												<NavigateNextIcon />
											</InputAdornment>
										),
									}}
									variant="outlined"
								/>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="margin-top-25 back-link" onClick={()=>this.props.navigate('/')}>&lt;<span>Back to Navigator</span></div>
						<div className="margin-top-25 font-20 font-weight-6 theme-grey1-fg">Introduction</div>
						<hr/>
						<Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
							<Grid item md={4} xs={12}>
								<div className="font-15 font-weight-6 theme-grey1-fg">About this tool</div>
							</Grid>
							<Grid item md={8} xs={12}>
								<div className="font-12 line-height-16">
									<p>The 'Universal Credit Navigator' tool is a prototype that allows a user to navigate the Universal Credit legislation interactively. It is designed by lawyers and underpinned by a machine readable version of the legislative rules .</p>
									<p>The tool is designed to guide the user quickly to legally accurate answers. It does not calculate an amount of universal credit. It does not provide legal advice.</p>
								</div>
							</Grid>
						</Grid>
						<hr/>
						<Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
							<Grid item md={4} xs={12}>
								<div className="font-15 font-weight-6 theme-grey1-fg">What is 'Universal Credit'</div>
							</Grid>
							<Grid item md={8} xs={12}>
								<div className="font-12 line-height-16">
									<p>Universal Credit is the main UK social security benefit for working age people. The tool covers the rules about entitlement and calculation of the benefit which are found mainly in Part 1 of the Welfare Reform Act 2012 and the Universal Credit Regulations 2013. Other legislation is covered where relevant. Procedural rules and regulations, for example those relating to decision making, claims and payments (including deduction of debt) are not covered in this version.</p>
								</div>
							</Grid>
						</Grid>
						<hr/>
						<Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
							<Grid item md={4} xs={12}>
								<div className="font-15 font-weight-6 theme-grey1-fg">How this tool works</div>
							</Grid>
							<Grid item md={8} xs={12}>
								<div className="font-12 line-height-16">
									<p>The tool follows the structure and logic of the legislation. It shows 9 topics in the order they appear in the legislation, beginning with the basic concepts relating to the benefit unit (or household) followed by entitlement conditions, assessment periods calculation etc..</p>
									<p>Each topic is divided into a list of inter-related question which the user can explore interactively as if they were reading the legislation. The individual questions presented to the user during the interactive session follow the words of the legislation without seeking to interpret or simplify it. Where a question is answered elsewhere (e.g. "are you a member of a couple") the user has the option to explore this before moving on. The user always has the option of checking the official version of the legislation via a link to legislation.gov.uk. Links to other information such as decision makers guidance and case law may be expanded in future versions.</p>
									<p>Some topics and question are not yet developed. In these cases the user will see a placeholder.</p>
								</div>
							</Grid>
						</Grid>
						<hr/>
						<Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
							<Grid item md={4} xs={12}>
								<div className="font-15 font-weight-6 theme-grey1-fg">Who this tool is for</div>
							</Grid>
							<Grid item md={8} xs={12}>
								<div className="font-12 line-height-16">
									<p>The tool is intended for anyone who needs to understand and apply the legislation, whether in their job role (e.g. as decision maker or adviser) or as a member of the public. We would welcome feedback.</p>
									<p><div className="text-underline font-weight-6 theme-darkteal-fg pointer" onClick={()=>this.props.navigate('/contact')}>Contact Us</div></p>
								</div>
							</Grid>
						</Grid>
						<a className="backtotop-link" href="#header">Back to Top</a>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(About);