import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavigateFunction, Location } from 'react-router-dom';
import './styles/header.scss';
import logo from '../assets/logo/logo-light.png';
import Button from '@mui/material/Button';
import { logout } from '../api/user';
import { NOTIFICATION_TYPE, notify, delay, withRouter, getTimestamp } from '../services';
import { updateUser, updateGroup, updateToken, updateExpired, updateTrial, updateLogined } from '../redux/actions/user';
import { Activity,Domain } from '../model/activity';
import { logActivity } from '../api/log';
import { addActivity,  clearActivity } from '../redux/actions/activity';
import { config } from '../config';

type Props = {
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string,
  activities: Activity[],
  updateUser: Function,
  updateGroup: Function,
  updateToken: Function,
  updateExpired: Function,
  updateTrial: Function,
  updateLogined: Function,
  addActivity: Function,
  clearActivity: Function
	navigate: NavigateFunction,
  location: Location
}
type States = {
}
class Header extends Component<Props, States> {

	componentDidMount() {
		if(!this.props.logined && window.location.pathname.split('/')[1].indexOf('login') === -1){
			this.props.navigate('/login');
      window.location.href='/login';
    }
	}

	componentDidUpdate() {
		if(!this.props.logined && window.location.pathname.split('/')[1].indexOf('login') === -1){
			this.props.navigate('/login');
      window.location.href='/login';
    }
	}

	logout = async () => {
    // @ts-ignored
    let domain: Domain = config.domains;
    let activities = this.props.activities;
    activities.push({domain: domain, path: window.location.hostname+this.props.location.pathname+this.props.location.search, action: 'User logout', time: getTimestamp()});
    await delay(100);
    await logActivity(this.props.token, activities);
    this.props.clearActivity();
    logout(this.props.token).then(async (res: any) => {
      this.props.updateToken('');
      this.props.updateUser('');
      this.props.updateGroup([]);
      this.props.updateExpired('');
      this.props.updateTrial(false);
      this.props.updateLogined(false);
      await delay(100);
      this.props.navigate('/login');
    }).catch((err: any) => {
      notify(NOTIFICATION_TYPE.DANGER, '', err?.data.msg ? err?.data.msg : 'Something wrong! Please try again!');
    });
  }

	render() {
		return (
			<>
				<div className="header" id="header">
					<div className="container">
						<img className="pointer" src={logo} alt="logo" onClick={()=>this.props.navigate('/')} />
						<div className="user">
							<div className="greeting">Hello, {this.props.user}</div>
							<Button variant="contained" onClick={this.logout}>
								Sign out
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired,
  activities: state.activity.activities
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUser: bindActionCreators(updateUser, dispatch),
    updateGroup: bindActionCreators(updateGroup, dispatch),
    updateToken: bindActionCreators(updateToken, dispatch),
    updateExpired: bindActionCreators(updateExpired, dispatch),
    updateTrial: bindActionCreators(updateTrial, dispatch),
    updateLogined: bindActionCreators(updateLogined, dispatch),
    addActivity: bindActionCreators(addActivity, dispatch),
    clearActivity: bindActionCreators(clearActivity, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));