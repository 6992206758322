import { Component } from 'react';
import { Routes, Route, Location } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './App.scss';
import NavigatorsView from './pages/navigatorsView';
import { ReactNotifications } from 'react-notifications-component';
import { updateUser, updateGroup, updateToken, updateExpired, updateTrial, updateLogined } from './redux/actions/user';
import { Activity,Domain } from './model/activity';
import { logActivity } from './api/log';
import { addActivity, updateActivity, clearActivity } from './redux/actions/activity';
import { delay, getTimestamp, withRouter } from './services';
import { config } from './config';

type Props = {
  location: Location,
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string,
  activities: Activity[],
  updateUser: Function,
  updateGroup: Function,
  updateToken: Function,
  updateExpired: Function,
  updateLogined: Function,
  updateTrial: Function,
  addActivity: Function,
  updateActivity: Function,
  clearActivity: Function
}

type States = {
}
class App extends Component<Props, States> {

  updateActivities: any;

  update = async () => {
    const activities = this.props.activities;
    await delay(100);
    if(activities.length>0 && this.props.logined && this.props.token !== '') {
      await logActivity(this.props.token, activities);
      await delay(100);
      this.props.clearActivity();
    }
  }


  handleRouteChange = async (location: any) => {
    if (this.props.logined && this.props.token !== '') {
      // @ts-ignored
      let domain: Domain = config.domains;
      this.props.addActivity({domain: domain, path: window.location.hostname+location.pathname+location.search, action: 'Switch page', time: getTimestamp()});
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.handleRouteChange(this.props.location);
    }
  }

  componentDidMount(){
    // @ts-ignored
    let domain: Domain = config.domains;
    this.props.addActivity({domain: domain, path: window.location.hostname+this.props.location.pathname+this.props.location.search, action: 'Open page', time: getTimestamp()});
    this.update();
    this.updateActivities = setInterval(()=> this.update(), 1000*30);
  }

  componentWillUnmount() {
    clearInterval(this.updateActivities);
  }

  render() {
    return (
      <>
        <ReactNotifications />
        <Routes>
          <Route path="/*" element={<NavigatorsView />} />
        </Routes>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired,
  activities: state.activity.activities
});

const mapDispatchToProps = (dispatch: any) => {
	return {
    updateUser: bindActionCreators(updateUser, dispatch),
    updateGroup: bindActionCreators(updateGroup, dispatch),
    updateToken: bindActionCreators(updateToken, dispatch),
    updateExpired: bindActionCreators(updateExpired, dispatch),
    updateTrial: bindActionCreators(updateTrial, dispatch),
    updateLogined: bindActionCreators(updateLogined, dispatch),
    addActivity: bindActionCreators(addActivity, dispatch),
    updateActivity: bindActionCreators(updateActivity, dispatch),
    clearActivity: bindActionCreators(clearActivity, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

