import { Component } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Login from './login';
import Header from './header';
import Footer from './footer';
import Navigator from './navigator';
import Privacy from './privacy';
import About from './about';
import Help from './help';
import Contact from './contact';
import './styles/navigator.scss';

type Props = {
	token: string,
  user: string,
  groups: {id: number, name: string, description: string}[],
	logined: boolean,
  trial: boolean,
  expired: string
}

type States = {
}

class NavigatorsView extends Component<Props, States> {

	render() {
		return (
    <>
			<div className={ window.location.pathname.split('/')[1].indexOf('login') === -1 ? 'navigator-content' : '' }>
        {window.location.pathname.split('/')[1].indexOf('login') === -1 ? <Header /> : <></>}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigator />} />
          <Route path="/:step" element={<Navigator />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/help" element={<Help />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {window.location.pathname.split('/')[1].indexOf('login') === -1 ? <Footer /> : <></>}
			</div>
		</>);
	}
}
const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
  groups: state.user.groups,
  trial: state.user.trial,
	logined: state.user.logined,
  expired: state.user.expired
});

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorsView);
