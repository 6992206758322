import { Component } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { NOTIFICATION_TYPE, notify, withRouter } from '../services';
import './styles/contact.scss';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { form } from '../api/message';

type Props = {
	token: string,
	navigate: NavigateFunction
}
type States = {
	nature: string,
	allowcontact: boolean,
	name: string,
	email: string,
	details: string,
	disabled: boolean
}
class Contact extends Component<Props, States> {
	
	constructor(props: Props) {
    super(props);
		this.state = {nature:'', allowcontact: true, name: '', email: '', details: '', disabled: false};
	}


	componentDidMount() {
    document.title = "Help -- Universal Credit Navigator";
	}

	handleChange = (event: SelectChangeEvent) => {
		this.setState({nature: event.target.value});
	}

	handleAllowChange = (event: SelectChangeEvent) => {
		this.setState({allowcontact: event.target.value==='Yes'?true:false});
	}

	handleNameChange = (event: any) => {
		this.setState({name: event.target.value});
	}

	handleEmailChange = (event: any) => {
		this.setState({email: event.target.value});
	}

	handleDetailsChange = (event: any) => {
		this.setState({details: event.target.value});
	}

	submit = () => {
		if(this.state.nature === '') {
			notify(NOTIFICATION_TYPE.DANGER, '', 'Please select the nature of your enquiry');
		} else if (this.state.allowcontact && (this.state.name === '' || this.state.email === '')) {
			notify(NOTIFICATION_TYPE.DANGER, '', 'Please input your contact details');
		} else if (this.state.details === '') {
			notify(NOTIFICATION_TYPE.DANGER, '', 'Please input your questions');
		} else {
			this.setState({disabled: true});
			form(this.props.token, 'DWP@UCNavigator.co.uk', this.state.nature, this.state.details, this.state.name, this.state.email).then((res: any) => {
				notify(NOTIFICATION_TYPE.SUCCESS, '', 'Your feedback is received');
				this.setState({nature: '', details: '', name: '', email: '', disabled: false});
			}).catch((err: any) => {
				notify(NOTIFICATION_TYPE.DANGER, '', err?.data.msg ? err?.data.msg: 'Something wrong, please try again later');
				this.setState({disabled: true});
			});
		}
	}

	render() {
		const ITEM_HEIGHT = 48;
		const ITEM_PADDING_TOP = 8;
		const MenuProps = {
			PaperProps: {
				style: {
					maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
					width: 250,
				},
			},
		};

		const natures = ["Feedback"];
		return (
			<>
				<div className="help">
					<div className="nav">
						<div className="container">
							<div className="search">
								<TextField
									label="Search"
									size="small"
									color="warning"
									InputProps={{
										endAdornment: (
											<InputAdornment className="search-btn" position="end">
												<NavigateNextIcon />
											</InputAdornment>
										),
									}}
									variant="outlined"
								/>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="margin-top-25 back-link" onClick={()=>this.props.navigate('/')}>&lt;<span>Back to Navigator</span></div>
						<Grid container className="margin-top-15" spacing={{ xs: 0, md: 3 }} columns={{ xs: 1, md: 12 }}>
							<Grid item md={4} xs={12}>
								<div className="font-20 font-weight-6 theme-grey1-fg">Contact Us</div>
							</Grid>
							<Grid item md={8} xs={12}>
								<div className="font-12 line-height-16">
									<p>For more information please email us at: <a className="font-style-italic text-underline font-weight-6 theme-darkteal-fg" href="mailto:DWP@UCNavigator.co.uk" target="_blank" rel="noreferrer">DWP@UCNavigator.co.uk</a></p>
									
									<hr/>

									<div className="margin-top-15 uppercase font-weight-6">Provide Feedback</div>

									<p>If you would like to provide feedback to the ongoing development of this application, please submit it in the form below.</p>

									<FormControl className="width-p100">
										<FormLabel className="font-weight-6">What was the nature of your enquiry (within the Universal Credit Navigator) <span className="fg-red2">*</span></FormLabel>
										<Select
											displayEmpty
											value={this.state.nature}
											onChange={this.handleChange}
											input={<OutlinedInput />}
											MenuProps={MenuProps}
											inputProps={{ 'aria-label': 'Without label' }}
											disabled={this.state.disabled}
										>
											<MenuItem disabled value="">
												<em>Please select one</em>
											</MenuItem>
											{natures.map((item: string) => (
												<MenuItem
													key={item}
													value={item}
												>
													{item}
												</MenuItem>
											))}
										</Select>
										<br/>
										<FormLabel id="details" className="font-weight-6">Your feedback <span className="fg-red2">*</span></FormLabel>
										<TextField value={this.state.details} onChange={this.handleDetailsChange}  multiline rows={4} aria-labelledby="details" variant="outlined" placeholder="Any questions?" disabled={this.state.disabled} />
										<br/>
										<FormLabel id="allow-contact" className="font-weight-6">Would you like to be contacted about your feedback? <span className="fg-red2">*</span></FormLabel>
										<RadioGroup
											aria-labelledby="allow-contact"
											name="allow-contact"
											value={this.state.allowcontact?"Yes":"No"}
											onChange={this.handleAllowChange}
										>
											<FormControlLabel value="Yes" control={<Radio />} label="Yes (please provide contact details below" disabled={this.state.disabled} />
											<FormControlLabel value="No" control={<Radio />} label="No" disabled={this.state.disabled} />
										</RadioGroup>
										<br/>
										<FormLabel id="name" className={this.state.allowcontact?"font-weight-6":"hide"}>Your name <span className="fg-red2">*</span></FormLabel>
										<TextField className={this.state.allowcontact?"":"hide"} value={this.state.name} onChange={this.handleNameChange} aria-labelledby="name" variant="outlined" placeholder="Name" disabled={this.state.disabled} />
										<br/>
										<FormLabel id="email" className={this.state.allowcontact?"font-weight-6":"hide"}>Your email <span className="fg-red2">*</span></FormLabel>
										<TextField className={this.state.allowcontact?"":"hide"} value={this.state.email} onChange={this.handleEmailChange} aria-labelledby="email" variant="outlined" placeholder="Email" disabled={this.state.disabled} />
									</FormControl>
									<br/><br/>
									<hr/>
									<FormLabel className="font-weight-6">Required Fields <span className="fg-red2">*</span></FormLabel>
									<div className="width-p100">
										<Button variant="contained" onClick={this.submit}>
											Submit
										</Button>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state: any) => ({
  token: state.user.token
});

const mapDispatchToProps = (dispatch: any) => {
	return {};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));