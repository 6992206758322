import { userActionTypes } from '../actions/user';

const userInitialState = {
  user: '',
  token: '',
  expired: '',
  groups: [],
  trial: false,
  logined: false
};

const userReducer = (state = userInitialState, action: any) => {
  switch (action.type) {
    case userActionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case userActionTypes.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case userActionTypes.UPDATE_EXPIRED:
      return {
        ...state,
        expired: action.payload.expired,
      };
    case userActionTypes.UPDATE_GROUP:
      return {
        ...state,
        groups: action.payload.groups,
      };
    case userActionTypes.UPDATE_TRIAL:
      return {
        ...state,
        trial: action.payload.trial,
      };
    case userActionTypes.LOGINED:
      return {
        ...state,
        logined: action.payload.logined,
      };
    default:
      return state;
  }
};

export default userReducer;