import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavigateFunction, Location } from 'react-router-dom';
import './styles/login.scss';
import logo from '../assets/logo/logo-light.png';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper  from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { login } from '../api/user';
import { Domain } from '../model/activity';
import { encryptWithAES, decryptWithAES, withRouter } from '../services';
import { updateUser, updateGroup, updateToken, updateExpired, updateTrial, updateLogined } from '../redux/actions/user';
import { addActivity, clearActivity } from '../redux/actions/activity';
import { getTimestamp } from '../services';
import { config } from '../config';

type Props = {
  updateUser: Function,
  updateGroup: Function,
  updateToken: Function,
  updateExpired: Function,
  updateTrial: Function,
  updateLogined: Function,
	navigate: NavigateFunction,
  addActivity: Function,
  clearActivity: Function,
  location: Location
}

type States = {
	username: string,
	password: string,
	isChecked: boolean,
  error: string,
  disabled: boolean
}

class Login extends Component<Props, States> {

  constructor(props: Props) {
    super(props);
    this.state = {username: '', password: '', isChecked: false, error: '', disabled: false};
    this.props.updateToken('');
    this.props.updateUser('');
    this.props.updateGroup([]);
    this.props.updateExpired('');
    this.props.updateTrial(false);
    this.props.updateLogined(false);
  }

  componentDidMount() {
    document.title = "Login -- Universal Credit Navigator";
		if (localStorage.remember && localStorage.username !== "") {
      this.setState({
        isChecked: true,
        username: localStorage.username,
        password: decryptWithAES(localStorage.password)
      });
    }
  }

	onChangeValue = (event: any) => {
		const name = event.target.name;
		if(name === 'username') {
			this.setState({username: event.target.value});
		}else if(name === 'password') {
			this.setState({password: event.target.value});
		}else if(name === 'remember') {
			const isChecked = !this.state.isChecked;
			this.setState({isChecked: isChecked});
			if(!isChecked){
				localStorage.removeItem('username');
				localStorage.removeItem('password');
				localStorage.removeItem('remember');
			}
		}
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    const { username, password, isChecked } = this.state;
    this.setState({error: 'Authenticating ...'});
    if(username.length >= 5 && username.length <= 25 && password.length >= 6){
      this.setState({disabled: true});
      login(username, password).then((res: any) => {
				if (isChecked && username !== "") {
					localStorage.username = username;
					localStorage.password = encryptWithAES(password);
					localStorage.remember = isChecked;
				} else {
					localStorage.removeItem('username');
					localStorage.removeItem('password');
					localStorage.removeItem('remember');
				}
        this.props.updateToken(res.data.token);
        this.props.updateUser(res.data.name);
        let roles = res.data.roles;
        roles = roles.sort((a: { id: number; name: number; }, b: { id: number; name: number; }) => {
          if (a.id !== b.id) {
              return a.id - b.id
          }
          if (a.name === b.name) {
            return 0;
          }
          return a.name > b.name ? 1 : -1;
        });
        this.props.updateGroup(roles);
        this.props.updateExpired(res.data.expired);
        this.props.updateTrial(res.data.trial === 1 ? true : false);
        this.props.updateLogined(true);
        this.setState({disabled: false});
        this.setState({error: ''});
        // @ts-ignored
        let domain: Domain = config.domains;
        this.props.addActivity({domain: domain, path: window.location.hostname+this.props.location.pathname+this.props.location.search, action: 'User login', time: getTimestamp()});
        //window.location.href = config.protocal+'//www.'+config.domain;
        this.props.navigate("/");
      }).catch((err: any) => {
        this.setState({disabled: false, error: err?.data.msg ? err?.data.msg: 'Something wrong, please try again later'});
      });
    }else{
      this.setState({disabled: false, error: 'Please input correct username/password'});
    }
  };

  render() {
		const { username, password, isChecked } = this.state;
    return (
      <>
        <div className="login_container">
          <div className="logo">
						<img src={logo} alt="logo" />
					</div>
					<Container className="padding-3 container" maxWidth="xs">
            <Paper className="padding-25" elevation={3} >
							<div className="title">Login</div>
							<div className={this.state.disabled ? 'center padding-top-8 font-10 fg-green3' : 'center padding-top-8 font-10 fg-red3'}>{ this.state.error }</div>
							<form className="padding-top-12" onSubmit={this.handleSubmit}>
								<div className="username">
									<TextField 
												fullWidth 
												label="Username" 
												name="username" 
												size="small" 
												variant="outlined" 
												value={username}
												onChange={this.onChangeValue}
											/>
								</div>
								<div className="password">
									<TextField
                        fullWidth
                        label="Password"
                        name="password"
                        size="small"
                        type="password"
                        variant="outlined"
												value={password}
												onChange={this.onChangeValue}
                      />
								</div>
								<div className="submit">
									<FormControlLabel control={<Checkbox name="remember" checked={isChecked} onChange={this.onChangeValue} />} label="Remember me"/>
									<Button type="submit" variant="contained" disabled={this.state.disabled} >
                    Sign in
                  </Button>
								</div>
							</form>
						</Paper>
					</Container>
        </div> 
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUser: bindActionCreators(updateUser, dispatch),
    updateGroup: bindActionCreators(updateGroup, dispatch),
    updateToken: bindActionCreators(updateToken, dispatch),
    updateExpired: bindActionCreators(updateExpired, dispatch),
    updateTrial: bindActionCreators(updateTrial, dispatch),
    updateLogined: bindActionCreators(updateLogined, dispatch),
    addActivity: bindActionCreators(addActivity, dispatch),
    clearActivity: bindActionCreators(clearActivity, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));