import { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReactHtmlParser from 'react-html-parser'; 
import "./flowchart.scss";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

type Item = {
	name: string,
	type: string,
	title?: string,
	top?: boolean,
	rtop?: boolean,
	right?: boolean,
	bottom?: boolean,
	rbottom?: boolean,
	NOTA?: string,
	link?: string,
	indent?: number,
	YN?: boolean|null,
	parent?: string,
	badge?: any,
	options?:{indent?: number, YN?: boolean}[],
	elements: {html: string, name: string}[]
}

type Props = {
	json: string|undefined
}

type States = {
	data: {type:string, data: Item[]},
	modalOpen: boolean,
	modalText: string|undefined, 
	modalButton: string|null
}

class FlowChart extends Component<Props, States> {

	constructor(props: Props) {
		super(props);  
		this.state = {data: {type:'',data:[]}, modalOpen: false, modalText: '', modalButton: null};
	}

	componentDidUpdate(prevProps: Props, prevState: States){
    if(this.props.json !== prevProps.json){
      this.componentDidMount();
    }
  }

	componentDidMount(){
		if(this.props.json !== undefined && this.props.json !== '[]') {
			let data = JSON.parse(this.props.json);
			this.setState({data: data});
		}
	}

	openModal = (text?: string, button?: string) => {
		this.setState({modalOpen: true, modalText: text?text:'', modalButton: button?button:null});
	}

	closeModal = () => {
		this.setState({modalOpen: false, modalText: '', modalButton: null});
	}

	getLeft = (item: Item, type: string) => {
		if(item?.indent) {
			if(item?.options) {
				if(item?.options.length===1){
					return (<><div className='col-1 item'></div>{item?.options.map((it: any, i: number)=> {
						if(it?.indent!==undefined) {
							return (<>{it.indent>0?(<div className={'col-'+it.indent+' item'}></div>):(<></>)}<div className={'col-1  item'} key={i}>{it?.YN!==undefined?(it?.YN===null?(<div className='line6'></div>):(ReactHtmlParser ("<div class='line6'><div class='YN'>"+(it?.YN?'Yes':'No')+"</div></div>"))):(<><div className="line4"></div></>)}</div></>);
						} else {
							return (<></>);
						}
					})}</>);
				} else {
					return (<><div className='col-1 item'></div>{item?.options.map((it: any, i: number)=> {
						if(it?.indent!==undefined) {
							return (<>{it.indent>0?(<div className={'col-'+it.indent+' item'}></div>):(<></>)}<div className={'col-1  item'} key={i}>{it?.YN!==undefined?(it?.YN===null?(<div className='line6'></div>):(ReactHtmlParser ("<div class='line6'><div class='YN'>"+(it?.YN?'Yes':'No')+"</div></div>"))):(<><div className="line4"></div></>)}</div></>);
						} else {
							return (<></>);
						}
					})}</>);
				}
			} else {
				if(type==='result') {
					return (<div className={'col-'+(item.indent+1)+' item'}></div>);
				} else {
					return (<>
										<div className={'col-' + item.indent + ' item'}></div>
										<div className='col-1 item'>
											{item?.badge!==undefined?(<div className='flowchart-badge'>{item?.badge}</div>):(<></>)}
											{item?.badge!==undefined&&item?.top ? 
												(item?.parent ? (<div className="line5"></div>) : (<div className="line1"></div>)) 
												:(<></>)}
											{item?.badge!==undefined&&item?.right ? 
												(<div className="line2"></div>) : (<></>)}
											{item?.badge!==undefined&&item?.bottom ? 
												(<div className="line3"></div>) : (<></>)}
										</div>
									</>);
				}
			}
		} else {
			if(type === 'result') {
				return (<div className='col-1 item'></div>);
			} else {
				return (<div className='col-1 item'>
									{item?.badge!==undefined?(<div className='flowchart-badge'>{item?.badge}</div>):(<></>)}
									{item?.badge!==undefined&&item?.top ? 
										(item?.parent ? (<div className="line5"></div>) : (<div className="line1"></div>)) 
										: (<></>)}
									{item?.badge!==undefined&&item?.right ? (<div className="line2"></div>) : (<></>)}
									{item?.badge!==undefined&&item?.bottom ? (<div className="line3"></div>) : (<></>)}
								</div>);
			}
		}
	}

	getRight = (item: Item, type: string) => {
		if(item?.indent){
			if (type === 'result') {
				return (<div className={'col-'+(12-item.indent-1)+' item1'}>
									{item?.top?
										(item?.YN!==undefined?
												(ReactHtmlParser ("<div class='line5'><div class='YN'>"+(item?.YN?'Yes':'No')+"</div></div>"))
												:(item?.rtop?(<><div className="line4"></div></>):(<></>)))
										:(<></>)}
									<div className='flowchart-result'>{ReactHtmlParser (item.elements[0].html)}</div>
									{item?.rbottom?(<><div className="line8"></div></>):(<></>)}
								</div>);
			} else {
				return (<div className={'col-' + (12 - item.indent - 1) + ' item1'}>
									{item?.top?
										(item?.YN!==undefined?
												(ReactHtmlParser ("<div class='line5'><div class='YN'>"+(item?.YN?'Yes':'No')+"</div></div>"))
												:(item?.rtop?(<><div className="line4"></div></>):(<></>)))
										:(<></>)}
									<div className={'flowchart-'+type}>
										<div className="flowchart-content">
											<div className='flowchart-title'>{item.title}</div>
											{ReactHtmlParser(item.elements[0].html)}
										</div>
										<div className="flowchart-help">
											{item?.NOTA ? (<div className="question-icon" onClick={() => this.openModal(item?.NOTA, item?.link)}>?</div>) : (<></>)}
										</div>
									</div>
									{item?.rbottom?(<><div className="line7"></div></>):(<></>)}
								</div>);
			}
		} else {
			if(type==='result'){
				return (<div className='col-11 item1'>{item?.rtop?(<div className="line4"></div>):(<></>)}<div className='flowchart-result'>{ReactHtmlParser (item.elements[0].html)}</div>{item?.rbottom?(<><div className="line8"></div></>):(<></>)}</div>);
			} else {
				return (<div className={'col-11 item1'}>
				{item?.rtop?(<div className="line4"></div>):(<></>)}
				<div className={'flowchart-'+type}>
					<div className="flowchart-content">
						<div className='flowchart-title'>{item.title}</div>
						{ReactHtmlParser(item.elements[0].html)}
					</div>
					<div className="flowchart-help">
						{item?.NOTA ? (<div className="question-icon" onClick={() => this.openModal(item?.NOTA, item?.link)}>?</div>) : (<></>)}
					</div>
				</div>
				{item?.rbottom?(<><div className="line8"></div></>):(<></>)}
			</div>);
			}	
		}
	}

	getSection = (item: Item, index: number, type: string) => {
		return (
			<div key={index} className='grid-container flowchart-section'>
				{this.getLeft(item, type)}
				{this.getRight(item, type)}
			</div>);
	}

	extractItem = (item: Item, index: number) => {
		if(item.type === 'start') {
			return (<div key={index} className='flowchart-start'>{ReactHtmlParser (item.elements[0].html)}</div>);
		} else {
			return this.getSection(item, index, item.type);
		}
	}

	render() {
		const modal_style = {
			position: 'absolute' as 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: '60%',
			maxWidth: 720,
			minWidth: 240,
			bgcolor: '#ebfbf9',
			border: '0px solid #ebfbf9',
			p: 4,
		};
		return (
			<>
				<Card className={this.props.json !==null && this.props.json !== '[]'? 'flowchart show bg-transparent': 'hide'} variant="outlined">
      		<CardContent>
						{this.state.data.data.map((item: Item, index: number) => this.extractItem(item, index))}
					</CardContent>
				</Card>
				<Modal
					open={this.state.modalOpen}
					onClose={()=>this.closeModal()}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={modal_style} className="moreInfo">
						<div className="close-btn" onClick={()=>this.closeModal()}><div className="close-icon">-</div></div>
						<div className="title">Notes</div>
						<div className="info">
							{(this.state.modalText!==undefined&&this.state.modalText!=='')? 
								ReactHtmlParser (this.state.modalText)
								:(<></>)
							}
						</div>
						{this.state.modalButton?(<><hr className="solid margin-top-5 margin-bottom-15" /><a className='link' href={this.state.modalButton} target='_blank' rel='noreferrer'>Sample</a></>):(<></>)}
					</Box>
				</Modal>
			</>
		);
	}
}

export default FlowChart;